import utils from '../utils/util';

export const buildControls = (config) => {
  const { controls } = config;
  const html = ['<div class="vplyr-video-loader-container">',
    '<div class="vplyr-video-loader">',
    '<div class="loader-inner one"></div>',
    '<div class="loader-inner two"></div>',
    '<div class="loader-inner three"></div>',
    '</div>',
    '</div><div class="vplyr-gradient-bottom"></div>'];
  html.push('<svg style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg">',
    '<defs>',
    '<symbol id="vplyr-muted" viewBox="0 0 32 32">',
    '<title>volume-off</title>',
    '<path d="M25.87 15.63l3.432 3.431c0.277 0.278 0.277 0.726 0 1.003s-0.726 0.277-1.003 0l-3.432-3.431-3.418 3.418c-0.278 0.278-0.728 0.278-1.006 0s-0.277-0.727 0-1.006l3.418-3.418-3.396-3.396c-0.277-0.277-0.277-0.726 0-1.003s0.726-0.277 1.003 0l3.396 3.396 3.431-3.431c0.278-0.278 0.727-0.278 1.006 0s0.278 0.727 0 1.005l-3.431 3.431zM14.161 27.297l-5.884-5.611h-3.653c-1.407 0-2.133-0.792-2.133-2.133v-7.111c0-1.363 0.703-2.133 2.133-2.133h3.653l5.884-5.611c1.546-1.449 3.263-0.853 3.263 1.629v19.341c0 2.458-1.686 3.109-3.263 1.63zM16.001 6.774c0-1.682-0.718-1.254-1.444-0.543-1.407 1.377-3.803 3.703-5.654 5.498h-3.568c-1.060 0-1.422 0.385-1.422 1.422v5.689c0 1.060 0.341 1.422 1.422 1.422h3.786c1.82 1.935 4.086 4.32 5.43 5.646 0.734 0.725 1.451 0.991 1.451-0.624 0-3.403 0-15.036 0-18.51z"></path>',
    '</symbol>',
    '<symbol id="vplyr-volume" viewBox="0 0 32 32">',
    '<title>volume-on</title>',
    '<path d="M15.913 5.241l-6.951 5.761c-0.522 0.433-1.349 0.731-2.028 0.731h-1.599c-0.001 0-0.001 0-0.002 0-0.736 0-1.333 0.596-1.333 1.332v5.857c0 0.733 0.599 1.332 1.335 1.332h1.598c0.681 0 1.505 0.298 2.028 0.731l6.951 5.76v-21.504zM8.111 22.013c-0.283-0.235-0.805-0.425-1.177-0.425h-1.598c-1.473-0-2.667-1.193-2.669-2.666v-5.857c0.001-1.472 1.194-2.665 2.667-2.665 0.001 0 0.001 0 0.002 0h1.598c0.368 0 0.895-0.19 1.177-0.425l8.112-6.722c0.565-0.469 1.023-0.255 1.023 0.481v24.52c0 0.734-0.459 0.949-1.023 0.481l-8.111-6.723zM21.739 21.863c-0.111 0.086-0.253 0.138-0.407 0.138-0.368 0-0.667-0.299-0.667-0.667 0-0.215 0.101-0.406 0.259-0.528 1.471-1.135 2.409-2.897 2.409-4.878 0-0.002 0-0.004 0-0.007 0-1.794-0.915-3.51-2.421-4.738-0.15-0.123-0.245-0.309-0.245-0.517 0-0.368 0.298-0.667 0.667-0.667 0.16 0 0.308 0.057 0.422 0.151 1.8 1.468 2.911 3.552 2.911 5.771 0 2.355-1.095 4.531-2.927 5.94zM23.739 27.196c-0.111 0.086-0.253 0.138-0.407 0.138-0.368 0-0.667-0.299-0.667-0.667 0-0.215 0.101-0.406 0.259-0.528 3.1-2.392 5.076-6.107 5.076-10.282 0-0.004 0-0.009 0-0.013 0-3.801-1.928-7.417-5.088-9.994-0.15-0.123-0.245-0.309-0.245-0.517 0-0.368 0.298-0.667 0.667-0.667 0.16 0 0.308 0.057 0.422 0.151 3.453 2.817 5.578 6.8 5.578 11.027 0 0.004 0 0.009 0 0.013 0 4.604-2.178 8.7-5.56 11.313z"></path>',
    '</symbol > ',
    '<symbol id="vplyr-exit-fullscreen" viewBox="0 0 32 32">',
    '< title > fullscreen - off</title >',
    '<path d="M31.23 0c-0.199 0-0.398 0-0.398 0.199l-11.13 11.13 0.596-5.963c0-0.397-0.199-0.596-0.596-0.795 0 0 0 0 0 0-0.398 0-0.596 0.199-0.596 0.596l-0.994 7.95c0 0.199 0 0.398 0.199 0.596s0.398 0.199 0.596 0.199l7.752-0.994c0.398 0 0.596-0.398 0.596-0.795s-0.398-0.596-0.795-0.596l-5.764 0.596 11.13-11.13c0.199-0.199 0.199-0.596 0-0.994-0.398 0.199-0.596 0-0.596 0v0zM0.621 0.199c0.199 0 0.398 0 0.398 0.199l11.13 11.13-0.795-5.963c0-0.397 0.199-0.596 0.596-0.795 0 0 0 0 0 0 0.398 0 0.596 0.199 0.596 0.596l1.391 7.752c0 0.199 0 0.398-0.199 0.596s-0.398 0.199-0.596 0.199l-7.752-0.994c-0.397 0-0.596-0.398-0.596-0.795s0.397-0.596 0.795-0.596l5.963 0.596-11.329-10.932c-0.199-0.199-0.199-0.596 0-0.994 0.199 0 0.199 0 0.397 0v0zM0.621 31.801c0.199 0 0.398 0 0.398-0.199l11.13-11.13-0.795 5.764c0 0.398 0.199 0.596 0.596 0.795 0 0 0 0 0 0 0.398 0 0.596-0.199 0.596-0.596l1.391-7.752c0-0.199 0-0.398-0.199-0.596-0.199 0-0.398-0.199-0.596 0l-7.752 0.795c-0.397 0-0.596 0.398-0.596 0.795s0.397 0.596 0.795 0.596l5.963-0.596-11.13 11.13c-0.199 0.199-0.199 0.596 0 0.994 0-0.199 0.199 0 0.199 0v0zM31.23 31.801c-0.199 0-0.398 0-0.398-0.199l-11.13-11.13 0.596 5.764c0 0.398-0.199 0.596-0.596 0.795 0 0 0 0 0 0-0.398 0-0.596-0.199-0.596-0.596l-0.994-7.752c0-0.199 0-0.398 0.199-0.596 0.199 0 0.398-0.199 0.596 0l7.752 0.994c0.398 0 0.596 0.398 0.596 0.795s-0.398 0.596-0.795 0.596l-5.764-0.596 11.13 11.13c0.199 0.199 0.199 0.596 0 0.994-0.398-0.398-0.398-0.199-0.596-0.199v0z"></path>',
    '</symbol >',
    '<symbol id="vplyr-enter-fullscreen" viewBox="0 0 32 32">',
    '<title>fullscreen-on</title>',
    '<path d="M12.862 18.276l-10.024 10.024 0.625-5.298c0.039-0.33-0.197-0.627-0.526-0.666-0.328-0.042-0.627 0.197-0.666 0.526l-0.827 7.015c-0.022 0.182 0.042 0.365 0.171 0.494 0.114 0.114 0.266 0.176 0.424 0.176 0.023 0 0.047-0.001 0.070-0.004l7.015-0.827c0.329-0.038 0.565-0.337 0.526-0.666s-0.336-0.568-0.666-0.526l-5.298 0.625 10.024-10.024c0.234-0.234 0.234-0.614 0-0.849s-0.613-0.234-0.847 0.001z"></path>',
    '<path d="M3.686 3.146l5.298 0.625c0.331 0.044 0.627-0.197 0.666-0.526s-0.197-0.627-0.526-0.666l-7.016-0.827c-0.183-0.024-0.365 0.041-0.494 0.171s-0.194 0.312-0.171 0.494l0.827 7.015c0.036 0.305 0.295 0.53 0.595 0.53 0.023 0 0.047-0.001 0.071-0.004 0.329-0.038 0.565-0.337 0.526-0.666l-0.626-5.298 10.024 10.024c0.117 0.117 0.27 0.176 0.424 0.176s0.307-0.058 0.424-0.176c0.234-0.234 0.234-0.614 0-0.849l-10.023-10.023z"></path>',
    '<path d="M28.702 22.335c-0.329 0.038-0.565 0.337-0.526 0.666l0.625 5.298-10.024-10.024c-0.234-0.234-0.614-0.234-0.849 0s-0.234 0.614 0 0.849l10.024 10.024-5.298-0.625c-0.332-0.042-0.627 0.197-0.666 0.526s0.197 0.627 0.526 0.666l7.015 0.827c0.023 0.003 0.047 0.004 0.070 0.004 0.158 0 0.311-0.062 0.424-0.176 0.13-0.13 0.193-0.312 0.171-0.494l-0.827-7.015c-0.037-0.33-0.339-0.569-0.665-0.526z"></path>',
    '<path d="M18.353 14.194c0.154 0 0.307-0.058 0.424-0.176l10.024-10.024-0.625 5.298c-0.039 0.33 0.197 0.627 0.526 0.666 0.024 0.003 0.048 0.004 0.071 0.004 0.3 0 0.559-0.225 0.595-0.53l0.827-7.015c0.022-0.182-0.042-0.365-0.171-0.494s-0.314-0.195-0.494-0.171l-7.015 0.827c-0.329 0.038-0.565 0.337-0.526 0.666s0.334 0.568 0.666 0.526l5.298-0.625-10.024 10.024c-0.234 0.234-0.234 0.614 0 0.849 0.116 0.116 0.27 0.174 0.423 0.174z"></path>',
    '</symbol>',
    '</defs >',
    '</svg > ')
  if (utils.inArray(controls, 'play-large')) {
    html.push('<div class="vplyr-large-button" data-video="play">',
      '<div class="btn-controls">',
      '<div class="btn-wrap">',
      '<div class="play"></div>',
      '<div class="pause"></div>',
      '</div>',
      '</div>',
      '</div>')
  }


  html.push('<div class="vplyr-bottom-container">')
  if (utils.inArray(controls, 'progress')) {
    html.push(
      '<div class="vplyr-progress-bar-container">',
      '<input id="seek{id}" type="range" min="0" max="100" value="0" step="0.1" class="vplyr-progress-bar" data-video="seek"/>',
      '<progress class="vplyr-progress-played" max="100" role="presentation"></progress>',
      '<progress class="vplyr-progress-buffer" max="100" value="100">',
      '<span>100.00</span>% buffered',
      '</progress>',
      '</div>'
    );
  }
  html.push('<div class="vplyr-controls">')
  html.push('<div class="left-controls">')
  if (utils.inArray(controls, 'play')) {
    html.push(
      '<div class="btn-controls">',
      '<div class="btn-wrap">',
      '<div class="play" data-video="play"></div>',
      '<div class="pause" data-video="pause"></div>',
      '</div>',
      '</div>'
    );
  }
  if (utils.inArray(controls, 'time')) {
    html.push(
      '<div class="time-mod-controls">',
      '<div class="control-currenttime">00:00</div>',
      '<div class="control-separator">/</div>',
      '<div class="control-duration">00:00</div>',
      '</div>'
    );
  }
  html.push('</div>')//close vplyr left controls
  html.push('<div class="right-controls">')
  if (utils.inArray(controls, 'fullscreen')) {
    html.push(
      '<div class="fullscreen-controls" data-video="fullscreen">',
      '<svg class="icon-exit-fullscreen">',
      '<use xlink:href="#vplyr-exit-fullscreen"></use>',
      '</svg>',
      '<svg class="icon-enter-fullscreen">',
      ' <use xlink:href="#vplyr-enter-fullscreen"></use>',
      '</svg>',
      '</div>'
    );
  }
  html.push('<div class="volume-controls">')
  if (utils.inArray(controls, 'mute')) {
    html.push(
      '<div class="vplyr-volume" data-video="mute">',
      '<svg class="icon-muted">',
      '<use xlink:href="#vplyr-muted"></use>',
      '</svg>',
      '<svg class="icon-volume">',
      '<use xlink:href="#vplyr-volume"></use>',
      '</svg>',
      '</div>'
    );
  }
  if (utils.inArray(controls, 'volume')) {
    html.push(
      '<div class="vplyr-volume-progress">',
      '<input type="range" id="volume{id}"  class="vplyr-volume-input"  min="0"  max="10" data-video="volume" value="8">',
      '<progress class="vplyr-volume-display" max="10" role="presentation"></progress>',
      '</div>'
    );
  }
  html.push('</div>')//close vplyr volume controls

  html.push('</div>')//close vplyr right controls

  html.push('</div>')//close vplyr controls
  html.push('</div>')//close
  return html.join('');
}